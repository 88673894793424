body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #B4FF9F;
  --secondary-color: #354259;
  --ternary-color: #852B4E;
  --tertiary-color: #5BAF30;

  --first-color: #3B6AFD;
  --second-color: #000000;
  --third-color: #ffffff;
}

.first-button {
  background-color: var(--first-color);
  color: var(--third-color);
  text-transform: capitalize;
  font-weight: bold;
  outline: none;
  border: none;
  opacity: 1;
  cursor: pointer;
  /* z-index: 9 !important; */
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c201f8 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c201f8;
  border-radius: 0px;
  border: 2px dotted #ffffff;
}

.custom-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.custom-shadow2 {
  box-shadow: rgba(255, 255, 255, 0.19) 0px 3px 6px, rgba(255, 255, 255, 0.23) 0px 3px 6px;
}