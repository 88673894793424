/* .testimonialSwiper {
    background-image: url(`../../../../../assets/images/testimonial/testimonial-background.svg);
    background-repeat: no-repeat;
    border: 5px solid var(--primary-color)
} */

.swiper {
    width: 100%;
    height: 100%;
}

.testimonialSwiper .swiper-slide {
    /* text-align: center; */
    /* font-size: 18px; */
    background-image: url(`../../../../../assets/images/testimonial/testimonial-background.svg);
    background-repeat: no-repeat !important;
    border: 5px solid var(--first-color);
    object-fit: cover;
    background-position: center;


    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.testimonialSwiper .swiper-pagination-bullet {
    background-color: var(--first-color);
}

.testimonialSwiperParent .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 40px !important;
}

@media only screen and (max-width: 769px) {

    .testimonialSwiperParent .swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        bottom: 10px !important;
    }

    .testimonialSwiperParent .testswipercss {
        height: 300px !important;
    }

    .testimonialSwiperParent .swiper-wrapper {
        height: 95%;
    }
}