.transparent-nav {
    color: var(--third-color);
    z-index: 2;
    max-height: 55px;
}

.transparent-nav .content {
    width: 100%;
}

.transparent-nav .navbar-brand {
    color: var(--third-color);
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold !important;
    padding: 0 !important;
    margin: 0 !important;
}

.transparent-nav .pages-links {
    background-color: var(--second-color);
}

.transparent-nav .navbar-brand .nav-link:hover,
.transparent-nav .navbar-brand .nav-link.active {
    color: var(--first-color);
}

.transparent-nav .nav-link {
    color: var(--third-color);
}

.transparent-nav .navbar-nav .nav-link:hover,
.transparent-nav .navbar-nav .nav-link.active {
    color: var(--first-color);
}

.transparent-nav .quote-button {
    background-color: var(--first-color);
    font-weight: bold;
}

.transparent-nav .quote-button .nav-link:hover,
.transparent-nav .quote-button .nav-link.active {
    color: var(--third-color);
}

.transparent-nav .social-link a {
    transition: all 1s ease;
}

.transparent-nav .social-link a:hover {
    transition: all 1s ease;
    transform: rotate(360deg);
}

.styles_typicalWrapper__1_Uvh::after {
    content: "" !important;
    animation: styles_blink__2CKyC 0.3s infinite step-start !important;
}

@media only screen and (max-width: 992px) {

    .transparent-nav .content {
        width: 100%;
    }

    .transparent-nav .pages-links {
        color: var(--third-color);
        background-color: var(--second-color);
    }

    .transparent-nav .pages-links .nav-link {
        color: var(--third-color);
    }

    .transparent-nav .link-style-mobile {
        border-bottom: 2px solid var(--first-color);
    }

    /* 
    .transparent-nav .navbar-toggler .navbar-toggler-icon {
        background-image: url(../../../assets/images/nav-images/toggler-icon-blue.svg) !important;
        background-repeat: no-repeat;
    }

    .transparent-nav .navbar-toggler .navbar-toggler-icon {
        background-image: url(../../../assets/images/nav-images/toggler-icon-blue.svg) !important;
        background-repeat: no-repeat;
    } */

    .transparent-nav .navbar-toggler-icon:focus {
        box-shadow: none !important;
    }

    .transparent-nav .navbar-toggler {
        color: var(--first-color) !important;
        border: none !important;
    }

    .transparent-nav .navbar-toggler:focus {
        color: var(--first-color) !important;
        border: none !important;
        box-shadow: none !important;
    }

    .transparent-nav .collapse-style {
        background-color: var(--second-color) !important;
        padding: 10px 30px !important;
        max-width: 60vw !important;
        position: absolute !important;
        right: 5px !important;
        top: 60px !important;
        border-radius: 4px !important;
        z-index: 2;
    }

    /* ... existing CSS ... */

    /* Style for the hamburger and close icons */
    /* .transparent-nav .navbar-toggler img {
        transition: transform 0.5s ease;
    } */

    /* Animation for the hamburger to close icon */
    /* .transparent-nav .navbar-toggler.active img {
        transform: rotate(90deg);
    } */

    /* Custom hamburger animation */
    .transparent-nav .navbar-toggler {
        width: 25px;
        height: 25px;
        position: relative;
        transition: .5s ease-in-out;
        background: transparent;
        border: none;
        padding: 5px 5px 0 0 !important;
    }

    .transparent-nav .navbar-toggler:focus,
    .transparent-nav .navbar-toggler:active {
        outline: none;
        box-shadow: none;
    }

    .transparent-nav .toggler-icon {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: var(--first-color);
        border-radius: 1px;
        opacity: 1;
        left: 0;
        top: 0;
        transition: .5s ease-in-out;
    }

    .transparent-nav .top-bar {
        margin-top: 4px;
        /* Adjust this value for vertical alignment */
    }

    .transparent-nav .middle-bar {
        margin-top: 12px;
        width: 70%;
        margin-left: 30%;
        /* Adjust this value for vertical alignment */
    }

    .transparent-nav .bottom-bar {
        margin-top: 20px;
        /* Adjust this value for vertical alignment */
    }

    /* State when the navbar is collapsed */
    .transparent-nav .navbar-toggler:not(.collapsed) .top-bar {
        transform: translateY(8px) rotate(225deg);
    }

    .transparent-nav .navbar-toggler:not(.collapsed) .middle-bar {
        opacity: 0;
    }

    .transparent-nav .navbar-toggler:not(.collapsed) .bottom-bar {
        transform: translateY(-8px) rotate(-225deg);
    }

    /* Add a transition for the .middle-bar when the menu is open */
    .transparent-nav .navbar-toggler.collapsed .middle-bar {
        opacity: 1;
        transition-delay: 0.3s ease-in-out;
        /* Delay the transition to avoid overlapping */
    }
}