/* custom carousel */

.managements-carousel .carousel-indicators {
  display: none !important;
}

.managements-carousel .carousel-control-next-icon {
  background-image: url('../../../../assets/images/managements-images/icons/next-icon.svg');
}

.managements-carousel .carousel-control-prev-icon {
  background-image: url('../../../../assets/images/managements-images/icons/prev-icon.svg');
}

.managements-carousel .carousel-control-next,
.managements-carousel .carousel-control-prev {
  position: absolute;
  z-index: 1;
  width: 3%;
  opacity: .5;
  transition: opacity .15s ease;
}

.managements-carousel .members-social-link a {
  color: #9B9B9B !important;
  transition: all 1s ease;
}

.managements-carousel .members-social-link a:hover {
  /* color: var(--secondary-color); */
  transition: all 1s ease;
  transform: rotate(360deg);
}

.management-team-indicator h6 {
  font-size: 1.2rem;
}

.management-team-indicator p,
.management-team-description {
  font-size: 0.9rem;
}

.management h2 {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative;
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--first-color);
  padding-bottom: 0.5em;
}

.management h2::after {
  content: '';
  background: var(--first-color);
  width: 20%;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 40%;
}

.management-team-indicator img {
  height: 70px;
  width: 70px;
  /* margin: 10px 20px; */
}

@media only screen and (max-width: 769px) {
  .managements-carousel .carousel-indicators {
    display: none !important;
  }

  .managements-carousel .carousel-control-next-icon {
    background-image: none !important;
  }

  .managements-carousel .carousel-control-prev-icon {
    background-image: none !important;
  }

  .management-team-indicator h6 {
    font-size: 1.0rem;
  }

  .management-team-indicator p,
  .management-team-description {
    font-size: 0.8rem;
  }

  .management h2 {
    font-size: 1.3rem;
  }

  .management h2::after {
    content: '';
    background: var(--first-color);
    width: 30%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 35%;
  }

  .management-team-indicator img {
    height: 60px;
    width: 60px;
    /* margin: 10px 20px; */
  }
}