.whatsappButton a {
    text-decoration: none;
}

.whatsappButton {
    width: 140px;
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 2;
}

.whatsappText {
    display: block !important;
    color: #fff;
}


@media only screen and (max-width: 1000px) {
    .whatsappButton {
        width: 50px;
        right: 10px;
    }

    .whatsappText {
        display: none !important;
    }
}