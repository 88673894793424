.faq .card {
    --bs-card-border-color: transparent !important;
}

.faq .card .show .game-item {
    background-color: #fff;
    border-bottom: 1px solid #B8B8B8;
}

.faq .card .show .game-item:last-child {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.faq .card {
    --bs-card-border-color: transparent !important;
    --bs-card-border-width: none !important;
    --bs-card-border-radius: 10px !important;
}

.faq .card-body {
    background-color: #fff !important;
    border-color: #fff !important;
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.faq .card {
    background-color: #fff;
    border-bottom: none !important;
    padding: 0 !important;
    --bs-card-bg: inherit;
}

.faq .card .show .faq-accordion-item {
    background-color: #fff;
    border-bottom: 1px solid #B8B8B8;
}

.faq .card .show .faq-accordion-item:last-child {
    background-color: #fff;
    border-color: #fff;
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* .faq .form-select {
    --bs-form-select-bg-img: url("../../Assets/Icons/down-caret-white-icon.svg");
} */