.trapezium-video-container {
    overflow: hidden;
    position: relative;
    clip-path: polygon(0% 40%, 100% 40%, 100% 85%, 0% 98%);
    margin-top: -20%;
}

@media only screen and (max-width: 768px) {
    .trapezium-video-container {
        clip-path: polygon(0% 15%, 100% 15%, 100% 85%, 0% 97%);
        margin-top: -5%;
    }
}

.white-hide-overlay {
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.300); */
}