.clients-section h3 {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    margin-bottom: 20px;
    font-size: 2rem;
    color: var(--first-color);
    padding-bottom: 0.5em;
}

.clients-section h3::after {
    content: '';
    background: var(--first-color);
    width: 10%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 15%;
}

.client {
    border: 15px solid #D9D9D9;
    padding: 10px;
    height: 100px;
    /* width: 90%; */
    width: 400px;
}

.model-area {
    position: relative;
}

.model-bg {
    position: absolute;
    bottom: 0;
}

.model {
    position: absolute;
    height: 100%;
    z-index: 1;
    left: 30%;
    bottom: 1%;
}

.model img {
    height: 100%;
    /* z-index: 1; */
}

.clients-model {
    width: 100%;
    height: 525px;
}

@media only screen and (max-width: 769px) {
    .clients-section h3 {
        font-size: 1.6rem;
    }

    .clients-section h3::after {
        content: '';
        background: var(--secondary-color);
        width: 20%;
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 40%;
    }

    .clients-model {
        height: 500px;
    }

    .model-bg {
        position: absolute;
        bottom: 8%;
    }

    .model {
        position: absolute;
        height: 100%;
        z-index: 1;
        left: 30%;
        bottom: 9%;
    }
}

@media only screen and (min-width: 1500px) {
    .clients-model {
        height: 600px;
    }
}

#crossfade img {
    position: absolute;
    top: 0px;
    left: 0px;
    color: transparent;
    opacity: 0;
    z-index: 0;
    -webkit-backface-visibility: hidden;
    -webkit-animation: imageAnimation 9s linear infinite 0s;
    -moz-animation: imageAnimation 9s linear infinite 0s;
    -o-animation: imageAnimation 9s linear infinite 0s;
    -ms-animation: imageAnimation 9s linear infinite 0s;
    animation: imageAnimation 9s linear infinite 0s;
}

#crossfade img:nth-child(2) {
    -webkit-animation-delay: 3s;
    -moz-animation-delay: 3s;
    -o-animation-delay: 3s;
    -ms-animation-delay: 3s;
    animation-delay: 3s;
}

#crossfade img:nth-child(3) {
    -webkit-animation-delay: 6s;
    -moz-animation-delay: 6s;
    -o-animation-delay: 6s;
    -ms-animation-delay: 6s;
    animation-delay: 6s;
}

@-webkit-keyframes imageAnimation {
    0% {
        opacity: 0;
        -webkit-animation-timing-function: ease-in;
    }

    8% {
        opacity: 1;
        /* -webkit-animation-timing-function: ease-out; */
    }

    17% {
        opacity: 1;
        -webkit-animation-timing-function: ease-out;
    }

    19% {
        opacity: 1;
    }

    35% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

@-moz-keyframes imageAnimation {
    0% {
        opacity: 0;
        -moz-animation-timing-function: ease-in;
    }

    8% {
        opacity: 1;
        -moz-animation-timing-function: ease-out;
    }

    17% {
        opacity: 1;
        -moz-animation-timing-function: ease-out;
    }

    19% {
        opacity: 1;
    }

    35% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

@-o-keyframes imageAnimation {
    0% {
        opacity: 0;
        -o-animation-timing-function: ease-in;
    }

    8% {
        opacity: 1;
        /* -o-animation-timing-function: ease-out; */
    }

    17% {
        opacity: 1;
        -o-animation-timing-function: ease-out;
    }

    19% {
        opacity: 1;
    }

    35% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

@-ms-keyframes imageAnimation {
    0% {
        opacity: 0;
        -ms-animation-timing-function: ease-in;
    }

    8% {
        opacity: 1;
        /* -ms-animation-timing-function: ease-out; */
    }

    17% {
        opacity: 1;
        -ms-animation-timing-function: ease-out;
    }

    19% {
        opacity: 1;
    }

    35% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

@keyframes imageAnimation {
    0% {
        opacity: 0;
        animation-timing-function: ease-in;
    }

    8% {
        opacity: 1;
        /* animation-timing-function: ease-out; */
    }

    17% {
        opacity: 1;
        animation-timing-function: ease-out;
    }

    19% {
        opacity: 1;
    }

    35% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}