.contact-form {
    color: var(--third-color);
}

.contact-form-input {
    width: 100%;
    height: 100%;
    border-width: 1px;
    color: #000;
    font-size: 14px;
    font-size: 0.875rem;
    padding: 13px 30px;
    background-color: var(--third-color);
    border-style: solid;
    border-color: #354259;
    -webkit-appearance: none;
    box-sizing: border-box;
    font-weight: normal;
    outline: 0;
    transition: all ease-in-out 0.3s;
    border-radius: 5px;
    width: 100%;
}

.contact-form textarea {
    height: 150px;
}

.contact-form-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--second-color);
    opacity: 1;
    /* Firefox */
}

.contact-form-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--second-color);
}

.contact-form-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--second-color);
}

.booking-button {
    outline: none;
    border: none;
    background-color: var(--first-color);
    font-weight: bold;
    color: var(--third-color);
    padding: 5px 25px;
    border-radius: 3px;
}