.transparent-nav-banner {
    /* background-image: url(`../../../../../assets/images/banner-images/services-banner.svg), url(`../../../../../assets/images/banner-images/bg-overlay.svg); */
    background-repeat: no-repeat;
    width: 100%;
    background-blend-mode: overlay;
    height: 350px;
    background-size: cover;
}

.transparent-nav-banner-home {
    height: 350px !important;
}

.transparent-nav-banner .navbar-brand:hover {
    color: var(--first-color);
}

.transparent-nav-banner .navbar-brand:focus {
    color: var(--third-color);
}

.transparent-nav-banner .home-banner-card {
    color: #fff;
    /* background-color: rgba(34, 34, 34, 0.504); */
    /* opacity: 0.8; */
    padding: 50px 100px;
    /* border-radius: 10px; */
}

.transparent-nav-banner .home-banner-card p {
    font-size: 18px;
}

.transparent-nav-banner .transparentNavBannerSwiper .swiper-pagination-bullet {
    background-color: var(--first-color);
}

.transparent-nav-banner .transparentNavBannerSwiper.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 0 !important;
}

@media only screen and (max-width: 769px) {
    .transparent-nav-banner {
        background-size: contain;
        width: 100%;
        height: 150px;
    }
}

@media only screen and (max-width: 992px) {
    .transparent-nav-banner {
        background-size: contain;
        width: 100%;
        height: 200px;
    }

    .transparent-nav-banner .home-banner-card {
        /* color: #fff; */
        /* background-color: rgba(34, 34, 34, 0.504); */
        /* opacity: 0.8; */
        padding: 15px 15px;
        border-radius: 7px;
    }

    .transparent-nav-banner .home-banner-card p {
        font-size: 14px;
    }

    .transparent-nav-banner .transparentNavBannerSwiper.swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        bottom: 0 !important;
    }
}