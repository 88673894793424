.section-title h3 {
    font-size: 20px;
    /* letter-spacing: 0; */
    position: relative;
    margin-bottom: 20px;
    font-size: 2rem;
    color: var(--first-color);
    padding-bottom: 0.5em;
}

.section-title h3::after {
    content: '';
    background: var(--first-color);
    /* width: 6%; */
    width: 50px;
    height: 3px;
    position: absolute;
    bottom: 0;
    /* left: 3%; */
    left: 30px;
}

.why-us {
    position: relative;
}

.why-us .section-title h3::after {
    content: '';
    background: var(--first-color);
    width: 50%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.vision-model {
    position: absolute;
}

.box {
    /* position: absolute; */
    /* align-self: flex-end; */
    animation-duration: 3s;
    animation-iteration-count: infinite;
    /* height: 200px; */
    /* margin: 0 auto; */
    transform-origin: bottom;
    width: 340px;
}

.bounce {
    animation-name: bounce;
    animation-timing-function: linear;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-50px);
    }

    100% {
        transform: translateY(0);
    }
}

@media only screen and (max-width: 769px) {
    .section-title h3 {
        font-size: 1.6rem;
    }

    .section-title h3::after {
        content: '';
        background: var(--first-color);
        width: 20%;
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 40%;
    }
}