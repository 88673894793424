.faq h2 {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative;
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--first-color);
  padding-bottom: 0.5em;
}

.faq h2::after {
  content: '';
  background: var(--first-color);
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0%;
}