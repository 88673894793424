.footer {
    color: var(--third-color);
    background-color: var(--second-color);
    background-size: cover;
    font-size: 0.9rem;
}

.logo-part {
    margin-top: 40px;
    margin-bottom: 50px;
    /* color: rgb(206, 206, 206); */
}

.location-part {
    margin-top: 40px;
    margin-bottom: 20px;
    /* color: rgb(206, 206, 206); */
    text-align: center;
}

.location-part-items {
    text-align: left;
}

.tiny-footer {
    color: var(--third-color);
    text-align: center;
    font-weight: bold;
    background-color: var(--first-color);
}

.location-part h4 {
    font-size: 20px;
    letter-spacing: 3px;
    position: relative;
    margin-bottom: 20px;
    font-size: 1.6em;
    color: var(--first-color);
    padding-bottom: 0.5em;
}

.location-part h4::after {
    content: '';
    background: var(--first-color);
    width: 20%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 40%;
}


.location-part li {
    list-style: none;
    /* padding-bottom: 20px; */
}

.location-part a,
.tiny-footer a {
    /* color: rgb(206, 206, 206); */
    text-decoration: none;
    letter-spacing: 3px;
    font-weight: bold;
    font-size: 14px;
    transition: 0.2s;
}

.location-part a:hover,
.tiny-footer a:hover {
    /* color: #fff; */
    transition: 0.2s;
    text-shadow: 1px 1px 20px rgba(0, 0, 0, 1);
    text-decoration: none
}



.social-contact-links-part {
    margin-top: 40px;
    margin-bottom: 50px;
    /* color: rgb(206, 206, 206); */
    text-align: right;
}


.social-contact-links-part h4 {
    font-size: 20px;
    letter-spacing: 3px;
    position: relative;
    margin-bottom: 20px;
    font-size: 1.6em;
    color: var(--first-color);
    padding-bottom: 0.5em;
}


.social-contact-links-part h4::after {
    content: '';
    background: var(--first-color);
    width: 20%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 80%;
}



.social-contact-links-part li {
    list-style: none;
    padding-bottom: 15px;
}


.social-contact-links-part a {
    /* color: rgb(206, 206, 206); */
    text-decoration: none;
    letter-spacing: 3px;
    font-weight: bold;
    font-size: 14px;
    transition: 0.2s;
}


.social-contact-links-part a:hover {
    /* color: #fff; */
    transition: 0.2s;
    text-shadow: 1px 1px 20px rgba(0, 0, 0, 1);
}

.social-contact-links-part img {
    cursor: pointer;
}

.social-contact-links-part .social-link a {
    transition: all 1s ease;
}

.social-contact-links-part .social-link a:hover {
    transition: all 1s ease;
    transform: rotate(-360deg);
}

@media screen and (max-width:769px) {
    .logo-part {
        margin-top: -50px;
    }

    .logo-part img {
        background-color: #fff;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0px -5px 15px #9B9B9B;
    }

    .location-part,
    .social-contact-links-part {
        margin-top: 0;
    }

    .footer {
        margin-top: 30px;
        font-size: 0.8rem;
    }
}

@media screen and (max-width:1000px) {
    .logo-part {
        text-align: center;
    }

    .logo-part h4::after {
        left: 40%;
    }
}

@media screen and (max-width:1000px) {
    .social-contact-links-part {
        text-align: center;
    }

    .social-contact-links-part h4::after {
        left: 40% !important;
    }
}