.service-page article {
    font-size: 0.9rem;
}

.services h2 {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
    position: relative;
    margin-bottom: 20px;
    font-size: 2rem;
    color: var(--first-color);
    padding-bottom: 0.5em;
}

.services h2::after {
    content: '';
    background: var(--first-color);
    width: 20%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 40%;
}

@media only screen and (max-width: 992px) {
    .service-page article {
        font-size: 0.8rem;
    }

    .services h2 {
        font-size: 1.6rem;
    }

    .services h2::after {
        content: '';
        background: var(--first-color);
        width: 30%;
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 35%;
    }
}